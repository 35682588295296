import React, { Component } from 'react';
import logo from '../images/psc-logo.svg';
import ReactMarkdown from 'react-markdown'
import { Footer } from '../components/footer';


const contentful = require("contentful");
const client = contentful.createClient({
  space: "vqm72qf5au7t",
  accessToken: "hjX5j7b1qBqlqlVXA-f9bV48gKSE31Ie8gSH3LWku20"
});

export class Privacy extends Component {
    static displayName = Privacy.name;

    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

        setTimeout(() => {
            document.body.classList.add('animate');
            document.body.classList.add('page-secondary');
        }, 250);

        client
            .getEntry("2QZfTuSwss7LVNckqXxgGG")
            .then(entry => {

                this.setState({
                    pageData: entry.fields.content,
                    title: entry.fields.title
                });

                console.log(entry);

            })
            .catch(err => console.log(err))
            .finally(() => {

                this.setState({
                    loading: false
                });

            });

    }
  
  render () {

  let contents = this.state.loading
  ? 
  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">Loading...</div>
  :

  <div className="container main">
    <div className="row">
        <div className="app-header-border"></div>

        <header className="App-header">
            <a className="App-home" href="/">
                <img src={logo} className="App-logo" alt="logo" />
            </a>
        </header>

        <div className="col-12 col-md-10 col-lg-9 mx-auto my-4 my-lg-5 fade-up">
            <div className="text-white">
                <h1 className="mb-4 mb-lg-5 bold-700">{this.state.title}</h1>
                <ReactMarkdown>{this.state.pageData}</ReactMarkdown>
            </div>
        </div>
    </div>
  </div>

    return (
      <div className="container-fluid px-0">
            {contents}
            <Footer />
      </div>
    );
  }
}
