import React, { Component } from 'react';
import formativeLogo from '../images/formativelogo-w.png';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
      super(props);
    }

    componentDidMount() {

    }
  
  render () {

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 ml-auto footer-logo col-sm-12">
                        <p>Page Status Check by</p><a href="https://formativeco.com" ><img src={formativeLogo} className="" alt="formative" /></a>
                    </div>
                    
                    <div className="col-md-6 ml-auto col-sm-12 footer-links">
                        <div className="links d-flex flex-wrap justify-content-end align-items-center">
                        <a
                            className="mr-2"
                            href="/contact">
                            Contact Us
                        </a>
                            <a
                            className="mr-2"
                            href="/faq">
                            FAQs
                        </a>
                        <a
                            className=""
                            href="https://formativeco.com/privacy"
                            target="_blank">
                            Privacy
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}
