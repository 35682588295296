import React, { useState, useEffect } from 'react';
import successIcon from '../images/success.png';
import logo from '../images/psc-logo.svg';
import { ReactComponent as GraphicSvg } from '../images/graphic.svg';
import Papa from 'papaparse';
import DataTable from 'react-data-table-component';
import CountUp from 'react-countup';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Footer } from '../components/footer';


const Results = () => {

  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("jid");

  const [successId, setSuccessId] = useState(null);
  const [successUrl, setSuccessUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitData, setSubmitData] = useState(null);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [resultsData, setResultsData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [loadingTime, setLoadingTime] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/results', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id
           })
        });
          
        const json = await response.json();
        //console.log(json);
        setIsLoadingResults(false);
        if(json["Status"] == "Running"){
          setIsLoadingResults(false);
         
        }else if(json["Status"] == "Done"){
          // Get results from S3
          getResultsFromS3();  
        }
        else{
          setIsLoadingResults(false);
        }
		} catch (error) {
        console.log("error", error);
		}
};

const getResultsFromS3 = async () => {

    let fileName = window.location.href;
    fileName = fileName.split('=')[1];
    const file = 'https://s3.us-west-2.amazonaws.com/results.pagestatuscheck.com/';

    //console.log('AWS FILE: ' + file);
    var url = file + fileName + '.csv'
    
    var rows = []
    var errorData = [];
    try {
      Papa.parse(url, {
        dynamicTyping: true,
      download: true,
      header: false,
      comments: "*=",
      complete: function(data) {
        rows = data.data
        // console.log(rows)
        rows.shift()
        

          rows = rows.filter(function (el) {
            // console.log(el[0])
            return el['dateTime'] != '';
          });
    
          rows.forEach(result => {
            if (result[0]){
              result[0] = new Date(result[0]).toLocaleString();
            }
          });

          rows.forEach(result => {
            
          if (result[0] != null && result[2] != 200){
              console.log(typeof(result))
              errorData.push([result])
            }
          });
    
          console.log(errorData.length)
          setDownloadLink(file + fileName + '.csv');
          setResultsData(rows);
          setErrorData(errorData);
          setIsLoadingResults(true);
          console.log(rows);
          console.log(errorData);
      }
      });
     
      

		} catch (error) {
        console.log("error", error);
        setIsLoadingResults(true);
        setDownloadLink(null);
		}
}


useEffect(() => {
  let domainTotal = localStorage.getItem("domainTotal");
  console.log('Domain Total: ' + domainTotal);

  let time = Math.ceil(domainTotal / 2);
  console.log('Time: ' + time);

  setLoadingTime(time);
});

useEffect(() => {


  const interval = setInterval(() => {
    fetchData()
    //console.log('This will be called every 2 seconds');
  }, 2000);

  if(isLoadingResults == true){
    clearInterval(interval);
  }
  return () => clearInterval(interval);
}, [isLoadingResults]);

  
  useEffect(() => {
    setTimeout(() => {
            document.body.classList.add('animate');
        }, 250);
  }, [])

  useEffect(() => {
    
    const fetchData = async () => {
      const data = await fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
           })
        });
      const json = await data.json();
  
      //console.log(json);
      setSubmitData(json);
      setIsLoading(false);
    }

  
    
    fetchData()
     
      .catch(console.error);;
  }, [])

  const resultsColumn  = [
    {
        name: 'Date',
        selector: row => row[0],
        sortable: true
    },
    {
      name: 'URL',
      selector: row => row[1],
      sortable: true
    },
    {
      name: 'Status',
      selector: row => row[2],
      sortable: true
    }
  ]

  const conditionalRowStyles = [
    {
      when: row => row[2] === '200',
      style: {
        backgroundColor: 'rgba(152,191,139, 0.15)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: row => row[2] != '200',
      style: {
        backgroundColor: 'rgba(202,0,19,.1)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const messages = [
    'Please wait. We are working hard on getting your results...',
    '83% of consumers expect websites to load in less than 3 seconds...',
    'Slow-loading websites lead to a $2.6 billion revenue loss each year...',
    'The average mobile landing page takes 7 seconds to load...',
    'Website viewers spend 80% of their time looking at the left half of your pages...',
    'On average internet users in the US visit over 130 web pages per day...',
    '70% of small businesses don’t have a CTA on their website homepage...',
    'Users spend 5.94 seconds looking at the main website image, on average...',
    'Over 50% of all website traffic comes from mobile devices...',
    '86% of people want to see product and service information on a website homepage...',
    '37% of users say that poor navigation and design cause them to leave websites...',
    'Augmented reality website experiences are trending upwards...',
    '70% longer user sessions are earned from websites that load in five seconds...'
  ];

  let loadingMessages = []

  messages.forEach((message) => {

    let msg = [];
    let arr = message.split(' ');
    let word = [];

    for (var j = 0; j < arr.length; j++) {

      for (var i = 0; i < arr[j].length; i++) {
        msg.push(<span className='letter'>{arr[j].charAt(i)}</span>)
      }

      word.push(<div className='word'>{msg}</div>);
      msg = [];

    }

    loadingMessages.push(<div className='message'>{word}</div>);
  });

  let messageList = [];
  loadingMessages.map((item, index) => {
      messageList.push(<li key={index}>{item}</li>);
  });

  messageList = shuffle(messageList);

  //messageList.push('Please wait while we get your results...');

  /**
 * Randomly shuffle an array
 * https://stackoverflow.com/a/2450976/1293256
 * @param  {Array} array The array to shuffle
 * @return {Array}       The shuffled array
 */
function shuffle (arr) {

  if (arr.length < 3) {
    return arr;
  }
  
  // Note the -2 (instead of -1) and the i > 1 (instead of i > 0):
  
  for (let i = arr.length - 2; i > 1; --i) {
      const j = 1 + Math.floor(Math.random() * i);
      [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  
  return arr;

}

  

  

    return (
      

<div className="App results-page">

      <div className="container main">

        <div className="row">
          <div className="app-header-border"></div>

          <div className="feature-section d-flex position-relative">

            <div className="col-12 col-md-8 col-lg-6 feature-text">
              <header className="App-header">
                <a className="App-home" href="/">
                  <img src={logo} className="App-logo" alt="logo" />
                </a>
              </header>
            </div>

            

          </div>


          {!isLoadingResults &&
          <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
            <div className="row">
              <div className='col-12 mx-auto position-relative d-flex align-items-center justify-content-center'>
                  {loadingTime &&
                    <div className="loading-time text-white h4 pb-4 mb-4">
                      <CountUp end='99' duration={loadingTime}/>%
                    </div>
                  }
                  {loadingMessages &&
                  <div className='text-white h4 loading-message d-flex flex-column'>
                    <span className='text-small small text-uppercase text-white d-none'>Loading</span>
                    <Carousel
                    autoPlay={true}
                    showStatus={false}
                    showIndicators={false}
                    infiniteLoop={true}
                    transitionTime={0}
                    interval={5000}>
                    {messageList}
                    </Carousel>
                  </div>
                  }
                  <div className="d-flex graphic-container justify-content-center">
                      <GraphicSvg/>
                  </div>
              </div>
            </div>
          </div>
          }
          {isLoadingResults &&
            <div className="mb-4 mb-md-5">
              <div className="results-section mt-4 mt-md-5">
                <div className="results-section-container bg-off-white p-4 p-md-5 d-flex flex-wrap rounded">
                  <div className="col-12 mb-4">
                    <div className="d-flex">
                        <img src={successIcon} className="pr-3 xml-icon icon" alt="xml icon" />
                        <div className="result-details">
                         
                          <span className="h3 bold-700 text-uppercase">Scan Results</span>
                          {isLoadingResults ? <p>Done loading</p> : <p>loading............</p>}
                        </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="d-flex text-green d-flex flex-column align-items-start">
                      <span className="h3 bold-700 large-number mb-2 large-number">
                        <CountUp end={resultsData?.length} duration={1}/>
                      </span> 
                      <span className="h4 bold-700 pl-4">URLs are healthy</span>
                      <p className="text-dark large italic">Returned a status of 200</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-1 border-right"></div>
                  <div className="col-12 col-md-5 offset-md-1">
                    <div className="d-flex text-red d-flex flex-column align-items-start">
                      <span className="h3 bold-700 large-number mb-2">
                        {errorData.length < 1 ? <CountUp end={0} duration={0}/> : <CountUp end={errorData?.length} duration={1}/>}
                       
                        </span> 
                      <span className="h4 bold-700 pl-4">URLs returned errors</span>
                      <p className="text-dark large italic">See the table below for a list of errors</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="errors-section off-white">
                <div className="error-section-container bg-off-white p-4 p-md-5 d-flex flex-wrap position-relative">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <span className="h4 bold-700">Results ({resultsData?.length}) </span>
                    {downloadLink && 
                      <a href={downloadLink} target="_blank" download className="btn btn-blue ml-auto text-uppercase">Download CSV</a>
                    }
                  </div>
                  {resultsData &&
                      <div className="light-box-shadow-x overlap-table w-100 fade-up fade-2 mt-4">
                          <DataTable
                          pagination
                          conditionalRowStyles={conditionalRowStyles}
                          columns={resultsColumn}
                          data={resultsData}
                      />
                    </div>
                  }

                  {errorData &&
                    <div className="d-flex flex-column w-100 mt-4 mt-md-5">

                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <span className="h4 bold-700">Errors ({errorData?.length})</span>
                      </div>

                      <div className="light-box-shadow-x overlap-table w-100 fade-up fade-2 mt-4">
                          <DataTable
                          pagination
                          conditionalRowStyles={conditionalRowStyles}
                          columns={resultsColumn}
                          data={errorData}
                      />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
            }
          </div>
       </div>
       <Footer />
    </div>
    );
  };
  
  export default Results;
  